import {lazy, Suspense} from 'react';

import {offset} from '@floating-ui/dom';
import {useTheme} from '@mui/material';
import {Box} from '@mui/system';
import {BrowserRouter, Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom';
import {ShepherdTour} from 'react-shepherd';

import HostContext from './HostContext';

import ErrorBoundary from 'Components/Error/ErrorBoundary.ts';
import TokenExpireModal from 'Components/Integration/TokenExpireModal';
import ErrorDisplay from 'Components/MessageDisplay/ErrorDisplay';
import SnackbarContainer from 'Components/SnackbarContainer/SnackbarContainer';
import ZmSpinner from 'Components/ZmSpinner';
import ZmWebHeader from 'Components/ZmWebHeader';
import {
  AdminFeaturesLayoutLoader,
  AppointmentsListLoader,
  BookingLinkHistoryLoader,
  BookingsListLoader,
  BrandingSettingsLoader,
  AccountSettingsLoader,
  AvailabilitySettingsLoader,
  ProfileSettingsLoader,
  ConnectCallbackLoader,
  MobileLoader,
  SettingsLayoutLoader,
  // WelcomeLoader,
  SetupLoader,
  PurchaseLoader,
  OrganizerLandingPageLoader,
  WorkflowsListLoader,
  AnalyticsLoader,
  RoutingListLoader,
  ManagedEventsLoader,
  PollViewHarnessLoader,
  RouteFormBookingPageLoader,
  GroupsLoader,
  TeamsLoader,
} from 'RouteComponents';
import MobileRoutes from 'RouteComponents/mobileRoutes';
import {useDetectZoomLanguage} from 'Utils/hooks';
import {isInIframe, useInitClientNotification, useRedirect, useTokenRevoke} from 'Utils/integration';

import 'Components/Fte/style.scss';
import 'shepherd.js/dist/css/shepherd.css';

import './App.css';


const NewCancelAdapter = lazy(() => import('Components/Cancellations/Adapters')
  .then((m) => ({default: m.NewCancelAdapter})));
const NewRescheduleUrlAdapter = lazy(() => import('Components/Reschedulings/Adapters')
  .then((m) => ({default: m.NewRescheduleUrlAdapter})));
const ErrorComponent = lazy(() => import('Components/Error/ErrorComponent'));
const ParseAppt = lazy(() => import('Components/ParseAppt'));

const AdminFeaturesLayout = lazy(AdminFeaturesLayoutLoader);
const AppointmentsList = lazy(AppointmentsListLoader);
const BookingLinkHistory = lazy(BookingLinkHistoryLoader);
const BookingsList = lazy(BookingsListLoader);
const BrandingSettings = lazy(BrandingSettingsLoader);
const AvailabilitySettings = lazy(AvailabilitySettingsLoader);
const AccountSettings = lazy(AccountSettingsLoader);
const ProfileSettings = lazy(ProfileSettingsLoader);
const ConnectCallback = lazy(ConnectCallbackLoader);
const Mobile = lazy(MobileLoader);
const SettingsLayout = lazy(SettingsLayoutLoader);
// const Welcome = lazy(WelcomeLoader);
const Setup = lazy(SetupLoader);
const Purchase = lazy(PurchaseLoader);
const OrganizerLandingPage = lazy(OrganizerLandingPageLoader);
const WorkflowsList = lazy(WorkflowsListLoader);
const Analytics = lazy(AnalyticsLoader);
const RoutingList = lazy(RoutingListLoader);
const ManagedEvents = lazy(ManagedEventsLoader);
const Groups = lazy(GroupsLoader);
const PollViewHarness = lazy(PollViewHarnessLoader);
const Teams = lazy(TeamsLoader);
const RouteFormBookingPage = lazy(RouteFormBookingPageLoader);

// const MobileRoutes = lazy(() => import('RouteComponents/mobileRoutes'));

// const hideHeaderRoutes = ['/settings/account', '/welcome', '/mobile'];
/**
 * Tries to detect if app is loaded in the web portal context and conditionally renders the
 *  top header logo bar
 * @param {Object} props
 * @return {ReactElement}
 */
const AppContainer = (props) => {
  const theme = useTheme();
  const {pathname} = useLocation();

  const showHeader = !isInIframe() && !pathname.startsWith('/me/') && !pathname.startsWith('/m/');
  return (
    <>
      {showHeader && <ZmWebHeader />}
      <HostContext.Provider value={{
        isHost: false,
      }}>
        <Box
          sx={{
            height: !showHeader ? '100vh' : 'calc(100vh - 56px)',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.default,
            scrollbarWidth: 'none',
          }}
        >
          {props.children}
        </Box>
      </HostContext.Provider>
    </>
  );
};

const tourOptions = {
  defaultStepOptions: {
    // cancelIcon: {
    //   enabled: true,
    // },
    // scrollTo: true,
    floatingUIOptions: {middleware: [offset({mainAxis: 20})]},
  },
  useModalOverlay: true,
};

/**
 * Wrapper for all host landing page web portal routes
 * for host page-specific logic
 * @return {ReactElement}
 */
export const HostLandingPageWrapper = () => {
  useRedirect();
  useTokenRevoke();
  useInitClientNotification();

  return (
    <ErrorBoundary fallback={
      <ErrorDisplay
        errorInfo={{
          reason: '',
        }}
        onReset={() => window.location.reload()}
      />
    }>
      <HostContext.Provider value={{
        isHost: true,
      }}>
        <ShepherdTour
          steps={[]}
          tourOptions={tourOptions}>
          <Outlet />
          <TokenExpireModal />
        </ShepherdTour>
      </HostContext.Provider>
    </ErrorBoundary>
  );
};

/**
 *
 * @return {ReactElement}
 */
function App() {
  useDetectZoomLanguage();
  const theme = useTheme();
  return (
    <div className="App" style={{background: theme.palette.background.default}}>
      <BrowserRouter>
        <AppContainer>
          <Suspense fallback={<ZmSpinner absolute />}>
            <Routes>
              <Route path="/m/*" element={<MobileRoutes />} />
              <Route
                path="/zbook?/manage/:attendeeId"
                element={<NewCancelAdapter />}
              />
              <Route
                path="/zbook?/manage/:attendeeId/rescheduling"
                element={<NewRescheduleUrlAdapter />}
              />
              <Route
                path="/zbook?/manage/:attendeeId/cancelling"
                element={<NewCancelAdapter />}
              />
              <Route path="/error" element={<ErrorComponent />} />
              <Route path="/health" element={'OK'} />
              {/* dynamic pattern has lower priority than static route, so it will not swallow existing URLs */}
              <Route
                path="/zbook?/:userSlug"
                element={<ParseAppt />}
              />
              <Route
                path="/zbook?/:userSlug/:apptSlug"
                element={<ParseAppt />}
              />
              <Route
                path="/zbook?/d/:userSlug/:apptSlug"
                element={<ParseAppt />}
              />
              <Route
                path="/zbook?/f/:formSlug/"
                element={<RouteFormBookingPage />}
              />
              <Route
                path="/zbook?/t/:userSlug/:apptSlug"
                element={<ParseAppt />}
              />
              <Route element={<HostLandingPageWrapper />}>
                <Route path="/me">
                  {/* <Route path="welcome" element={<Welcome />} /> */}
                  <Route path="welcome">
                    <Route path="setup" element={<Setup />} />
                    <Route path="*" element={<Navigate to={`setup`} />} />
                    <Route path="" element={<Navigate to={`setup`} />} />
                  </Route>
                  <Route path="purchase" element={<Purchase />} />
                  <Route path="mobile" element={<Mobile />} />
                  <Route path="settings" element={<SettingsLayout />}>
                    <Route path="account" element={<AccountSettings />} />
                    <Route path="availability" element={<AvailabilitySettings />} />
                    <Route path="branding" element={<BrandingSettings />} />
                    <Route path="profile" element={<ProfileSettings />} />
                    <Route path="*" element={<Navigate to={`account`} />} />
                    <Route path="" element={<Navigate to={`account`} />} />
                  </Route>
                </Route>
                <Route
                  path="/connect_callback/:b64Encoding"
                  element={<ConnectCallback />}
                />
                <Route
                  path="/me"
                  element={<OrganizerLandingPage />}
                >
                  <Route path="appts" element={<AppointmentsList />} />
                  <Route path="appts/history" element={<BookingLinkHistory />} />
                  <Route path="events" element={<BookingsList />} />
                  <Route path="workflows" element={<WorkflowsList />} />
                  <Route path="analytics" element={<Analytics />} />
                  <Route path="routing" element={<RoutingList />} />
                  <Route path="admin" element={<AdminFeaturesLayout />}>
                    <Route path="managed" element={<ManagedEvents />} />
                    <Route path="groups" element={<Groups />} />
                    <Route path="teams" element={<Teams />} />
                    <Route path="routing" element={<RoutingList />} />
                    <Route path="*" element={<Navigate to={`managed`} />} />
                    <Route path="" element={<Navigate to={`managed`} />} />
                  </Route>
                  <Route path="polls/:pollId" element={<PollViewHarness />} />
                  <Route path="*" element={<Navigate to={`appts`} />} />
                  <Route path="" element={<Navigate to={`appts`} />} />
                </Route>
                {/* reserved for web portal iframe src, when web changed to /me this route can be deleted */}
                <Route
                  path="/:b64Encoding"
                  element={<OrganizerLandingPage />}
                >
                  <Route path="appts" element={<AppointmentsList />} />
                </Route>
                <Route path="/" element={<Navigate to={`me`} />} />
              </Route>
            </Routes>
          </Suspense>
        </AppContainer>
        <SnackbarContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
