import {useEffect} from 'react';

import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {getUserInfo} from './integration';

import {
  fetchUserProfileNameSyncLockSetting,
  fetchUserProfilePicSyncLockSetting,
  patchUserSyncLock,
} from 'Store/HostSettingsStore';

// profileSyncLock has account level value and user level value, if they don't match,
// we should patch the user level value automatically to make sure it's under account admin's control.
export const useFixProfileSync = () => {
  const dispatch = useDispatch();
  const userProfilePicSyncLock = useSelector((state) => state.hostSettingsState.userProfilePicSyncLock);
  const userProfileNameSyncLock = useSelector((state) => state.hostSettingsState.userProfileNameSyncLock);

  const adminControlUseWebProfile = useSelector((state) => {
    return (state.hostSettingsState.profilePicture.hasLoaded &&
        !state.hostSettingsState.profilePicture.adminProfileSyncLocked) ||
    (state.hostSettingsState.displayName.hasLoaded && !state.hostSettingsState.displayName.adminProfileSyncLocked);
  });
  useEffect(() => {
    dispatch(fetchUserProfilePicSyncLockSetting());
    dispatch(fetchUserProfileNameSyncLockSetting());
  }, [dispatch]);

  useEffect(() => {
    if (adminControlUseWebProfile) {
      if (userProfilePicSyncLock === 'true') {
        dispatch(patchUserSyncLock({
          type: 'pic',
          value: 'false',
        }));
      }
      if (userProfileNameSyncLock === 'true') {
        dispatch(patchUserSyncLock({
          type: 'name',
          value: 'false',
        }));
      }
    }
  }, [dispatch, userProfilePicSyncLock, userProfileNameSyncLock, adminControlUseWebProfile]);
};

export const useDetectZoomLanguage = () => {
  const {i18n} = useTranslation();
  const webPortalLanguage = useSelector((state) => state.integrationState.webPortalLanguage);
  useEffect(() => {
    // _zm_lang is injected by Zoom Client
    if (window.__system_params__?._zm_lang) {
      i18n.changeLanguage(window.__system_params__._zm_lang);
    } else if (webPortalLanguage) {
      i18n.changeLanguage(webPortalLanguage);
    } else {
      const {language} = getUserInfo() || {};
      if (language) {
        i18n.changeLanguage(language);
      }
    }
  }, [i18n, webPortalLanguage]);
};
