import {createAsyncThunk, createSlice, miniSerializeError} from '@reduxjs/toolkit';

import {bookPollForCalendar} from './HostAppointmentsStore';

import {merge} from '../Utils/index';

import {
  clearAdhocReservedEvents,
  createOneOffAppointment,
  deleteEvent,
  deletePendingEvent,
  editAvailabilityPoll,
  editBooking,
  getPendingEvents,
  getPendingEvent,
  listBookings,
  removeBookerFromEventNew,
} from 'Api/ZCalendar';
import {HttpError} from 'Error/HttpError';
import {EVENT_STATUS, USER_RESPONSE_STATUS} from 'Utils/consts';

const initialState = {
  pageToken: null,
  bookings: [],
  pendingItems: {
    items: [],
    isLoading: false,
  },
  isLoading: false,
  errorState: {
    isError: false,
    reason: '',
  },
  nextPageToken: null,
  latestRequestId: null,
  isTabKeyEnabled: true, // used for a11y
};

export const listBookingsForCalendar = createAsyncThunk(
  'hostBookings/listByCalendar',
  async ({asScope, query}, thunkAPI) => {
    const response = await listBookings(asScope, query);
    return response;
  }
);

export const editBookingsForCalendar = createAsyncThunk(
  'hostBookings/editBooking',
  async ({asScope, data, eventId}, thunkAPI) => {
    const response = await editBooking(asScope, eventId, data);
    return response;
  }
);

export const deleteBookingForCalendar = createAsyncThunk(
  'hostBookings/deleteBooking',
  async ({asScope, comment, eventId}, thunkAPI) => {
    await deleteEvent(asScope, eventId, comment);
  }
);

export const deleteEventBookerForCalendar = createAsyncThunk(
  'hostBookings/removeBooker',
  async ({asScope, attendeeId, data, eventId}, thunkAPI) => {
    // eslint-disable-next-line no-unused-vars
    const {email, ...restData} = data;
    await removeBookerFromEventNew(asScope, attendeeId, restData);
  }
);

export const listPendingForCalendar = createAsyncThunk(
  'hostBookings/listPending',
  async ({query}, thunkAPI) => {
    const response = await getPendingEvents(query);
    return response;
  }
);

export const listPendingEventForCalendar = createAsyncThunk(
  'hostBookings/listPendingEvent',
  async ({pendingId}, thunkAPI) => {
    const response = await getPendingEvent(pendingId);
    return response;
  }
);
export const removePendingEvent = createAsyncThunk(
  'hostBookings/removePendingEvent',
  async ({asScope, pendingEventId}, thunkAPI) => {
    const response = await deletePendingEvent(asScope, pendingEventId);
    return response;
  }
);

export const clearReservedEvents = createAsyncThunk(
  'hostBookings/clearReservedEvents',
  async ({pendingEventId}, thunkAPI) => {
    await clearAdhocReservedEvents(pendingEventId);
  },
  {
    serializeError: (e) => {
      if (e instanceof HttpError) {
        return {
          ...miniSerializeError(e),
          body: e.body,
          errorCode: e.errorCode,
        };
      }
      return miniSerializeError(e);
    },
  }
);

export const editPollForCalendar = createAsyncThunk(
  'hostBookings/editPoll',
  async ({calendarId, clearReservedEvents, data, notifyVoters, pollId, reFetchAdhocEvents}, thunkAPI) => {
    if (clearReservedEvents) {
      // must clear reserved events BEFORE patching the availability poll, else references may be lost already.
      await clearAdhocReservedEvents(pollId);
    }
    let response = await editAvailabilityPoll(calendarId, pollId, data, notifyVoters);
    if (reFetchAdhocEvents) {
      const recentPending = await getPendingEvents(calendarId ? {user: calendarId} : undefined);
      response = await recentPending.items.find((event) => event.id === pollId);
    }
    return response;
  },
  {
    serializeError: (e) => {
      if (e instanceof HttpError) {
        return {
          ...miniSerializeError(e),
          body: e.body,
          errorCode: e.errorCode,
        };
      }
      return miniSerializeError(e);
    },
  }
);


export const createOneOffEvent = createAsyncThunk(
  'hostBookings/createOneOffEvent',
  async ({calendarId, data, reFetchAdhocEvents}, thunkAPI) => {
    let response = await createOneOffAppointment(calendarId, data);
    if (reFetchAdhocEvents) {
      const recentPending = await getPendingEvents(calendarId ? {user: calendarId} : undefined);
      response = await recentPending.items.find((event) => event.id === response.id);
    }
    return response;
  }
);

export const hostBookingsStore = createSlice({
  name: 'HostBookingsStore',
  initialState,
  reducers: {
    addOneOffPendingEvent: (state, action) => {
      state.pendingItems.items.push(action.payload);
    },
    setTabKeyEnabled: (state, action) => {
      state.isTabKeyEnabled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listBookingsForCalendar.fulfilled, (state, action) => {
      // In order to avoid racing conditions when switching from upcoming to previous tabs
      // this request must only be fulfilled for the latest call.
      if (action.meta.requestId === state.latestRequestId) {
        state.isLoading = false;
        if (action.meta.arg.query.pageToken) {
          state.bookings = merge(state.bookings,
            action.payload.items.filter((appt) => appt.status !== EVENT_STATUS.CANCELLED), 'id');
        } else {
          state.bookings = action.payload.items;
        }
        state.pageToken = action.payload.nextPageToken;
        state.errorState = {
          isError: false,
          reason: '',
        };
      }
    });
    builder.addCase(listBookingsForCalendar.pending, (state, action) => {
      state.latestRequestId = action.meta.requestId;
      state.isLoading = true;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(listBookingsForCalendar.rejected, (state, action) => {
      state.isLoading = false;
      state.errorState = {
        isError: true,
        reason: 'storeErrors.listBookingsForCalendar',
      };
    });

    builder.addCase(editBookingsForCalendar.fulfilled, (state, action) => {
      const matchId = (element) => element.id === action.payload.id;
      const index = state.bookings.findIndex(matchId);
      state.bookings[index] = action.payload;
    });
    builder.addCase(editBookingsForCalendar.pending, (state, action) => {
      state.latestRequestId = action.meta.requestId;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(editBookingsForCalendar.rejected, (state, action) => {
      state.errorState = {
        isError: true,
        reason: 'Error updating booking',
      };
    });

    builder.addCase(deleteBookingForCalendar.fulfilled, (state, action) => {
      state.bookings = state.bookings.filter((event) => action.meta.arg.eventId !== event.id);
    });

    builder.addCase(deleteEventBookerForCalendar.fulfilled, (state, action) => {
      const eventIndex = state.bookings.findIndex((event) => event.id === action.meta.arg.eventId);
      if (eventIndex > -1) {
        const deletedBookerEmail = action.meta.arg.data.email;
        const userIndex = state.bookings[eventIndex].attendees.findIndex((user) => user.email === deletedBookerEmail);
        const storeEventUser = state.bookings[eventIndex].attendees[userIndex];
        storeEventUser.responseStatus = USER_RESPONSE_STATUS.DECLINED;
        storeEventUser.comment = action.meta.arg.data.comment || '';
        storeEventUser.booker = false;
      } else {
        console.error('rdx: no matching event booking found');
      }
    });

    builder.addCase(listPendingForCalendar.pending, (state, action) => {
      state.nextPageToken = null;
      state.pendingItems.isLoading = true;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });

    builder.addCase(listPendingForCalendar.fulfilled, (state, action) => {
      state.pendingItems.items = action.payload.items;
      state.pendingItems.isLoading = false;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });

    builder.addCase(listPendingForCalendar.rejected, (state, action) => {
      state.isLoading = false;
      state.errorState = {
        isError: true,
        reason: 'storeErrors.listBookingsForCalendar',
      };
    });

    builder.addCase(listPendingEventForCalendar.fulfilled, (state, action) => {
      const matchId = (element) => element.id === action.payload.id;
      const index = state.pendingItems.items.findIndex(matchId);
      state.pendingItems.items[index] = action.payload;

      state.pendingItems.isLoading = false;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });

    builder.addCase(listPendingEventForCalendar.pending, (state, action) => {
      state.pendingItems.isLoading = true;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });

    builder.addCase(listPendingEventForCalendar.rejected, (state, action) => {
      state.isLoading = false;
      state.errorState = {
        isError: true,
        reason: 'storeErrors.listBookingsForCalendar',
      };
    });

    builder.addCase(removePendingEvent.pending, (state, action) => {
      state.pendingItems.isLoading = true;
    });

    builder.addCase(removePendingEvent.fulfilled, (state, action) => {
      state.pendingItems.isLoading = false;
      state.pendingItems.items = state.pendingItems.items.filter(
        (pendingEvent) => action.meta.arg.pendingEventId !== pendingEvent.id
      );
    });

    builder.addCase(clearReservedEvents.fulfilled, (state, action) => {
      const itemIdx = state.pendingItems.items.findIndex(
        (pendingEvent) => action.meta.arg.pendingEventId === pendingEvent.id
      );
      if (itemIdx >= 0) {
        state.pendingItems.items[itemIdx].reserveTimes = false;
        state.pendingItems.items[itemIdx].reservedEvents = null;
      }
    });

    builder.addCase(editPollForCalendar.fulfilled, (state, action) => {
      const idx = state.pendingItems.items.findIndex((item) => item.id === action.payload.id);
      state.pendingItems.items[idx] = {...action.payload};
    });

    builder.addCase(createOneOffEvent.pending, (state, action) => {
      state.pendingItems.isLoading = true;
    });

    builder.addCase(createOneOffEvent.fulfilled, (state, action) => {
      state.pendingItems.items.push(action.payload);
      state.pendingItems.isLoading = false;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });

    builder.addCase(createOneOffEvent.rejected, (state, action) => {
      state.pendingItems.isLoading = false;
      state.errorState = {
        isError: true,
        reason: 'storeErrors.listBookingsForCalendar',
      };
    });

    builder.addCase(removePendingEvent.rejected, (state, action) => {
      state.pendingItems.isLoading = false;
      state.errorState = {
        isError: true,
        reason: 'storeErrors.listBookingsForCalendar',
      };
    });
    builder.addCase(bookPollForCalendar.fulfilled, (state, action) => {
      state.pendingItems.isLoading = false;
      state.pendingItems.items = state.pendingItems.items.filter(
        (pendingEvent) => action.meta.arg.pendingEventId !== pendingEvent.id
      );
    });
    builder.addCase(bookPollForCalendar.pending, (state, action) => {
      state.pendingItems.isLoading = true;
    });
  },
});

export const {addOneOffPendingEvent, setTabKeyEnabled} = hostBookingsStore.actions;
export default hostBookingsStore.reducer;
