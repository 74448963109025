// eslint-disable-next-line
import './Utils/polyfill';
import React, {useRef} from 'react';
import ReactDOM from 'react-dom/client';

import {CssBaseline} from '@mui/material';
import asyncStats from '@zoom/asynccomm-stats';
import {Provider} from 'react-redux';

import './i18n';
// eslint-disable-next-line no-unused-vars
import {mockWebPortalPostMessage} from './mocks/webPortalMock';
import reportWebVitals from './reportWebVitals';
import ThemedApp from './ThemedApp';

import CaptchaContext from 'Components/Captcha/CaptchaContext';
import CaptchaDownload from 'Components/Captcha/CaptchaDownload';
import {zschedulerURL} from 'services/schedulerInternal.ts';
import {store} from 'Store/Store';
import {METRIC_NAME, APPT_TYPE} from 'Utils/consts';
import {global as Bus} from 'Utils/EventBus';
import {getApptType, getReqWithIdRegex} from 'Utils/index.js';
import {
  initializeToken,
  isTokenExpired,
  adjustParentPageStyle,
} from 'Utils/integration';

import './index.css';

asyncStats.init({});
if (process.env.NODE_ENV === 'development') {
  const token =
    new URLSearchParams(window.location.search).get('tok') ||
    require('mocks/testToken.json')?.token ||
    '';
  asyncStats.setToken({token: `Bearer ${token}`});
}
// change the padding to inner part
adjustParentPageStyle('.content-body', 'padding', '0');
window.isFromO365 = window.location.href.indexOf('clientType=calFor365') > 0 ? true : false;

const {fetch: originalFetch} = window;
// response interceptor
window.fetch = async (...args) => {
  const response = await originalFetch(...args);
  if (response.status === 401 && isTokenExpired()) {
    Bus.emit('tokenExpired');
  }
  return response;
};

// Start Mock Service Worker in development
const ENABLE_MOCK = false; // Set to false to disable mock for testing
const DISABLE_GTM = true; // Set to true to disable otSdk from loading on the msw level (set to false for pw testing)
if (process.env.NODE_ENV === 'development') {
  const isBrowserTest =
    new URLSearchParams(window.location.search).get('testing') === 'true';
  if (!isBrowserTest) {
    const {getWorker} = require('./mocks/browser');
    try {
      const worker = getWorker({
        enableMocks: ENABLE_MOCK,
        disableGtm: DISABLE_GTM,
      });
      worker
        .start({
          onUnhandledRequest: 'bypass',
        })
        .catch(() => {});
    } catch {}
    if (ENABLE_MOCK) {
      mockWebPortalPostMessage();
    }
  }
}

const CaptchaProvider = ({children}) => {
  const captchaRef = useRef();
  return (
    <>
      <CaptchaDownload ref={captchaRef} />
      <CaptchaContext.Provider value={captchaRef}>
        {children}
      </CaptchaContext.Provider>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

initializeToken(() => {
  asyncStats.register({
    metricName: METRIC_NAME.API_STATUS,
    isMatch: () => !!window?._asyncStats?.token,
    cb: ({req, res}) => {
      try {
        let metricName = '';
        let description = asyncStats.descriptionStatus.SUCCESS;
        if (!res.ok) {
          const pathName = new URL(res.url).pathname;
          const matchedRegex = getReqWithIdRegex(pathName);
          if (matchedRegex) {
            metricName = METRIC_NAME.FAILED_API + '_' + matchedRegex;
          } else {
            metricName = METRIC_NAME.FAILED_API + '_' + pathName;
          }
          description = asyncStats.descriptionStatus.FAIL;
        } else {
          if (req.length === 2 && req[1].method === 'POST') {
            const apptURL = `${zschedulerURL}appointments`;
            if (req[0].includes(`${apptURL}/singleUseLink?`)) {
              metricName = METRIC_NAME.GENERATE_ONE_TIME_LINK;
            }
            if (req[0].includes(`${apptURL}?`)) {
              // create appointment success
              const appt = JSON.parse(req[1].body);
              if (getApptType(appt) === APPT_TYPE.ROUND_ROBIN) {
                metricName = METRIC_NAME.CREATE_ROUND_ROBIN;
              } else if (getApptType(appt) === APPT_TYPE.COLLECTIVE) {
                metricName = METRIC_NAME.CREATE_COLLECTIVE;
              } else if (getApptType(appt) === APPT_TYPE.RECURRING) {
                metricName = METRIC_NAME.CREATE_RECURRING;
              }
            }
            if (req[0].includes(`${apptURL}/adhoc`)) {
              // create adhoc success
              const appt = JSON.parse(req[1].body);
              if (getApptType(appt) === APPT_TYPE.ONE_OFF) {
                metricName = METRIC_NAME.CREATE_ONE_OFF;
              } else if (getApptType(appt) === APPT_TYPE.POLL) {
                metricName = METRIC_NAME.CREATE_MEETING_POLL;
              }
            }
          }
        }
        if (metricName) {
          asyncStats.report({
            metricName,
            description,
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
  });
  root.render(
    <>
      <CssBaseline />
      <Provider store={store}>
        <CaptchaProvider>
          <ThemedApp />
        </CaptchaProvider>
      </Provider>
    </>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
