import copy from 'copy-to-clipboard';
import {
  // endOfMonth,
  // endOfWeek,
  format,
  isSameDay,
  // startOfMonth,
  // startOfWeek,
} from 'date-fns';
import {Base64} from 'js-base64';
import {cloneDeep, unionBy} from 'lodash';
import escape from 'lodash/escape';
import {DateTime, IANAZone, Interval} from 'luxon';

import {isTeamAppt, getApptIntervalType} from './apptAvailabilityUtils';
import {
  ACCESS_ROLES,
  ADD_ON_TYPE,
  APPT_CREATOR_TYPE,
  APPT_TYPE,
  CAPACITY_TYPE,
  REQ_WITH_ID_REGX,
  SCHEDULE_COLOR_IDS,
  SCOPE_TYPE,
  SORT_BY_TYPE,
  ZM_CREATE_TYPE,
} from './consts';
import {getToken, getUserInfo, selfToScope} from './integration';

import {toDateTime} from 'Components/BookingBlocksWidget/utils';
import {getI18nLanguage} from 'i18n';

/**
 * Copy text to clipboard
 * @param {string} text
 * @return {Promise}
 */
export async function copyTextToClipboard(text) {
  let res;
  try {
    res = await navigator.clipboard.writeText(text);
  } catch {
    res = copy(text);
  }
  return res;
}

/**
 * Copy text to clipboard
 * @param {string} text
 * @return {Promise}
 */
export async function copyHTMLToClipboard(text) {
  return copy(text, {format: 'text/html'});
  // ClipboardItem is not supported in Firefox; use copy-to-clipboard document.execCommand until it is supported.
  // const html = [new ClipboardItem({'text/html': new Blob([text], {type: 'text/html'})})];
  // return navigator.clipboard.write(html);
}

/**
 * Copy binary data to clipboard
 * @param {Promise} promise
 * @param {string} blobType
 * @return {Promise}
 */
export function copyBlobToClipboard(promise, blobType = 'image/png') {
  // safari requires copy blob must be invoked synchronously in user click gesture.
  // if blob is acquired asynchronously(for example, canvas.toBlob), we should use Promise as value.
  const data = [
    new ClipboardItem({
      [blobType]: promise,
    }),
  ];
  return navigator.clipboard.write(data);
}

/**
 * Capitalizes string
 * @param {string} str
 * @return {string}
 */
export function capitalizeStr(str) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return '';
}

/**
 * Parses the given jwt's payload
 * @param {string} token
 * @return {Object}
 */
export function readJWTPayload(token) {
  const tokenParts = token.split('.');
  const jwtPayload = JSON.parse(Base64.decode(tokenParts[1]));
  return jwtPayload;
}

// For attendees
export const isCciCalendar = (organizerEmail) => {
  // return true; // to test cci account UI

  const cciCalIdRegex = new RegExp(/^contact_center_.+@group\.scheduler\.zoom\.us$/);
  return cciCalIdRegex.test(organizerEmail);
};

// For hosts
export const isCciToken = (jwtPayload) => {
  // return true; // to test cci account UI

  // For CCI accounts, jwt payload 'ty' === 10
  if (jwtPayload) {
    return jwtPayload.ty === 10;
  } else {
    try {
      const jwtPayload = readJWTPayload(getToken());
      if (!jwtPayload.eml) {
        console.error('invalid token eml');
      } else {
        return jwtPayload.ty === 10;
      }
    } catch (e) {
      console.error('could not parse token');
    }
  }
};

/**
 * escape any message html content
 * @param {String} message
 * @return {String}
 */
export function htmlEscape(message) {
  return escape(message);
}

/**
 * Check if a string has no special characters
 * @param {String} inputStr
 * @return {Boolean}
 */
export function hasNoSpecialCharacters(inputStr) {
  const noSpecialChars = new RegExp(/^[^<>"'=:;.,?/\\!@#$%^&*()_\-[\]+=`~{}]*$/, 'gi');
  return !!inputStr.match(noSpecialChars);
}

/**
 * Check if a string has no html symbols
 * @param {String} inputStr
 * @return {Boolean}
 */
export function hasNoHtmlSymbols(inputStr) {
  const noSpecialChars = new RegExp(/^[^<>"'&/\\]*$/, 'gi');
  return !!inputStr.match(noSpecialChars);
}

/**
 * Check the inputStr for invalid special characters expected of an email
 * @param {String} inputStr
 * @return {Boolean}
 */
export function hasValidCharactersEmail(inputStr) {
  // eslint-disable-next-line max-len
  const emailRegExp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'gi');
  return !!inputStr.toLowerCase().match(emailRegExp);
}

/**
 * Attempts to get different supported time zone formats depending on Intl format support,
 *  using long, longOffset, and IANA formats.
 * @param {?Date} date optional date to use
 * @param {?String} iana optional iana timezone to use
 * @param {?Boolean} hideLocalOffset optional hideLocalOffset
 * @return {String} a string with local timezone info in supported formats
 */
export function renderLocalTimeZone(date, iana, hideLocalOffset) {
  try {
    const ianaZone = iana || new Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localeInfo = new Intl.DateTimeFormat('default', {timeZone: ianaZone, timeZoneName: 'longGeneric'});
    const formattedParts = localeInfo.formatToParts(date);

    const localTimeZone = formattedParts.find(({type}) => type === 'timeZoneName').value;

    // try to retrieve info
    let localOffset = '';
    try {
      const localeOffsetInfo = new Intl.DateTimeFormat('default', {timeZone: ianaZone, timeZoneName: 'longOffset'});
      const offset = localeOffsetInfo.formatToParts(date).find(({type}) => type === 'timeZoneName').value;
      localOffset = `(${offset})`;
    } catch {}

    let referenceCity = '';
    try {
      const formattedIana = ianaZone.slice(ianaZone.lastIndexOf('/') + 1).replace('_', ' ');
      if (localTimeZone.startsWith('GMT')) {
        referenceCity = '';
      } else if (!localTimeZone.includes(formattedIana) && localTimeZone === '') {
        referenceCity = ` ${formattedIana}`;
      } else if (!localTimeZone.includes(formattedIana)) {
        referenceCity = `- ${formattedIana}`;
      }
    } catch {}
    if (hideLocalOffset) localOffset = '';
    return [localOffset, localTimeZone, referenceCity].filter((x) => x).join(' ');
  } catch (e) {
    console.error('error collecting tz info', e);
    if (iana) {
      return iana;
    }
    return '';
  }
};

/**
 * Attempts to get different supported time zone formats depending on Intl format support,
 *  using long, longOffset, and IANA formats.
 * @param {?Date} date optional date to use
 * @param {?String} iana optional iana timezone to use
 * @param {?Boolean} hideLocalOffset optional hideLocalOffset
 * @return {String} a string with local timezone info in supported formats
 */
export function renderTimeZoneShort(date, iana, hideLocalOffset) {
  try {
    const ianaZone = iana || new Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localeInfo = new Intl.DateTimeFormat('default', {timeZone: ianaZone, timeZoneName: 'longGeneric'});
    const formattedParts = localeInfo.formatToParts(date);
    const localTimeZone = formattedParts.find(({type}) => type === 'timeZoneName').value;

    // try to retrieve info
    let localOffset = '';
    try {
      const localeOffsetInfo = new Intl.DateTimeFormat('default', {timeZone: ianaZone, timeZoneName: 'longOffset'});
      const offset = localeOffsetInfo.formatToParts(date).find(({type}) => type === 'timeZoneName').value;
      localOffset = `(${offset})`;
    } catch {}

    if (hideLocalOffset) localOffset = '';
    return [localOffset, localTimeZone].filter((x) => x).join(' ');
  } catch (e) {
    console.error('error collecting tz info', e);
    if (iana) {
      return iana;
    }
    return '';
  }
};

/**
 * sorts list by date created
 * @param {Array} list
 * @return {Array}
 */
export const sortByCreatedDate = (list) => {
  return list.sort((a, b) => {
    const keyA = new Date(a.created);
    const keyB = new Date(b.created);
    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;
    return 0;
  });
};

/**
 * Sort original appts by different sort type
 * @param {Array} originAppts
 * @param {string} sortByType
 * @return {void}
 */
export const sortOriginApptsByType = (originAppts, sortByType) => {
  let sortFunc;
  if (sortByType === SORT_BY_TYPE.ALPHABETICAL) {
    sortFunc = (a, b) => a.summary.localeCompare(b.summary);
  } else if (sortByType === SORT_BY_TYPE.DATE_CREATED) {
    sortFunc = (a, b) => new Date(b.created) - new Date(a.created);
  } else if (sortByType === SORT_BY_TYPE.LAST_MODIFIED) {
    sortFunc = (a, b) => new Date(b.updated) - new Date(a.updated);
  }
  originAppts.forEach((appt) => {
    appt.appointments.sort(sortFunc);
  });
};

/**
 * @param {Array} timezones
 * @return {Array}
 */
export function sortByTimes(timezones) {
  return timezones.sort((a, b) => {
    const dateTimeA = DateTime.local().setZone(a);
    const dateTimeB = DateTime.local().setZone(b);
    const offsetA = dateTimeA.offset;
    const offsetB = dateTimeB.offset;
    if (offsetA !== offsetB) {
    // Timezones have different offsets, so sort by offset
      return offsetA - offsetB;
    }
    // Timezones have the same offset, so sort alphabetically
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });
};

/**
 * Gets user locale time format information
 * @return {Object}
 */
export function getTimeFormat() {
  const localeInfo = new Intl.DateTimeFormat(undefined, {hour: 'numeric'}).resolvedOptions();
  let timeFormat = 'h:mm';
  let showAMPM = true;
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/hourCycle
  switch (localeInfo.hourCycle) {
    case 'h12':
      timeFormat = 'h:mm';
      showAMPM = true;
      break;
    case 'h11':
      timeFormat = 'K:mm';
      showAMPM = true;
      break;
    case 'h23':
      timeFormat = 'HH:mm';
      showAMPM = false;
      break;
    case 'h24':
      timeFormat = 'kk:mm';
      showAMPM = false;
      break;
    default:
      break;
  }

  return {
    timeFormat,
    showAMPM,
  };
}

/**
 * Gets time format string from setting
 * return 'HH:mm' if time format setting is '24h', otherwise return 'h:mm' (or 'h:mm a' if showAMPM is true)
 * @param {string} timeFormatSetting '24h' or '12h'
 * @param {boolean} showAMPM
 * @return {Object}
 */
export function getTimeFormatFromSetting(timeFormatSetting, showAMPM) {
  return timeFormatSetting === '24h' ?
    'HH:mm' :
    showAMPM ?
      'h:mm a' :
      'h:mm';
}

/**
 * Returns list of canonical timezones that are supported by the browser only
 * https://en.wikipedia.org/wiki/List_of_tz_database_time_zones#Type
 * @return {string[]}
 */
export function getTimeZoneList() {
  const tzData = require('tzdata');
  return Object.keys(tzData.zones).filter(
    (key) => typeof tzData.zones[key] === 'object'
  );
};

/**
 * Tries to convert given tz name from its alias name into its canonical name
 * https://en.wikipedia.org/wiki/List_of_tz_database_time_zones#Type
 * @param {string} tz IANA timezone
 * @return {string}
 */
export function getCanonicalTz(tz) {
  const {zones} = require('tzdata');
  if (typeof zones[tz] === 'string') {
    // Is a Link
    return zones[tz];
  } else if (typeof zones[tz] === 'object') {
    // Is a canonical time zone
    return tz;
  } else {
    console.error('no tzdata found for', tz);
    // Try to find similar zone in tzdb or fallback to empty string
    const similarZone = findSimilarTz(tz);
    return similarZone || '';
  }
}

/**
 * Tries to find a similar enough canonical timezone in the tzdb from the given tzname
 * Will return a match in this priority:
 * - matching offset + in same region + both following same dst
 * - matching offset + both following same dst
 * @param {string} tz IANA timezone
 * @return {string}
 */
export function findSimilarTz(tz) {
  try {
    const {zones} = require('tzdata');
    if (IANAZone.isValidZone(tz)) {
      // Timezone is not in tzdb but is recognized;
      // try to get a suitable matching timezone from the tzdb
      const luxonTz = IANAZone.create(tz);
      const tzRegionPrefix = tz.split('/')[0];
      const similarZones = [];
      const sameRegionSimilarZones = [];
      for (let i = 0; i < Object.keys(zones).length; i++) {
        const key = Object.keys(zones)[i];
        const matchZone = IANAZone.create(key);
        if (
          // is a canonical tz
          typeof zones[key] === 'object' &&
          // respects same dst offset rules in Jan 1, 1970
          matchZone.offset(0) === luxonTz.offset(0) &&
          // and Jun 1, 1970
          matchZone.offset(13046400000) === luxonTz.offset(13046400000)
        ) {
          similarZones.push(key);
          if (key.split('/')[0] === tzRegionPrefix) {
            sameRegionSimilarZones.push(key);
            // stop after finding the first ideal match
            break;
          }
        }
      }
      return sameRegionSimilarZones[0] || similarZones[0] || '';
    }
  } catch {}
}

/**
 * Helper function to return the overall timezone name given a target timezone
 * Ex. America/Indiana will return America/New_York
 * @param {Object} groupedTimeZones
 * @param {String} target
 * @return {String}
 */
export function findTzArrKey(groupedTimeZones, target) {
  const foundKey = groupedTimeZones.find((key, i) => {
    const arr = groupedTimeZones[i].group;
    return arr.findIndex((item) => item === target) !== -1;
  });

  if (foundKey) {
    return foundKey.name;
  } else {
    return target;
  }
}

/**
 * Helper function to add label field in the timezone object
 * consisting of the form (GMT) Name - Main City
 * Assumes that the array will be in sorted order per https://github.com/vvo/tzdb
 * @param {Array} timezones
 * @param {any} t
 * @return {Array}
 */
export function buildTzArr(timezones, t) {
  return timezones.map((timezone) => {
    let localOffset = '';
    try {
      const gmt = timezone.currentTimeFormat.split(' ');
      localOffset = `(GMT${gmt[0]})`;
    } catch {}

    let label = '';
    const timeZoneName = timezone.alternativeName;
    const translatedTzName = t(`timeZoneName.${timeZoneName}`) === `timeZoneName.${timeZoneName}` ?
      timeZoneName :
      t(`timeZoneName.${timeZoneName}`);
    if (timezone.mainCities[0] !== '') {
      const mainCity = `${timezone.mainCities[0]}`;
      const translatedMainCity = t(`timeZoneCity.${mainCity}`) === `timeZoneCity.${mainCity}` ?
      mainCity :
      t(`timeZoneCity.${mainCity}`);
      label = `${localOffset} ${translatedTzName} - ${translatedMainCity}`;
    } else {
      label = `${localOffset} ${translatedTzName}`;
    }

    return {
      ...timezone,
      label,
    };
  });
}

/**
 * Render a formatted long time interval string
 * @param {Date} start
 * @param {Date} end
 * @param {Date} timeFormatSetting
 * @return {String} time interval string
 */
export function formatTimeIntervalLong(start, end, timeFormatSetting) {
  const {showAMPM, timeFormat} = timeFormatSetting ?
    {
      showAMPM: timeFormatSetting !== '24h',
      timeFormat: getTimeFormatFromSetting(timeFormatSetting),
    } :
    getTimeFormat();

  if (showAMPM) {
    if (isSameDay(start, end)) {
      if ((start.getHours() < 12 && end.getHours() < 12) ||
        (start.getHours() >= 12 && end.getHours() >= 12)) {
        return format(start, `EEEE MMMM d, ${timeFormat} - `) + format(end, `${timeFormat} a`);
      }
      return format(start, `EEEE MMMM d, ${timeFormat} a - `) + format(end, `${timeFormat} a`);
    }
    return format(start, `EEEE MMMM d, ${timeFormat} a - `) + format(end, `EEEE MMMM d, ${timeFormat} a`);
  }

  if (isSameDay(start, end)) {
    return format(start, `EEEE MMMM d, ${timeFormat} - `) + format(end, `${timeFormat}`);
  }
  return format(start, `EEEE MMMM d, ${timeFormat} - `) + format(end, `EEEE MMMM d, ${timeFormat}`);
}

/**
 * Render a formatted long time interval string
 * @param {DateTime} start
 * @param {DateTime} end
 * @param {DateTime} timeFormatSetting
 * @return {String} time interval string
 */
export function formatTimeIntervalLongDT(start, end, timeFormatSetting) {
  const {showAMPM, timeFormat} = timeFormatSetting ?
    {
      showAMPM: timeFormatSetting !== '24h',
      timeFormat: getTimeFormatFromSetting(timeFormatSetting),
    } :
    getTimeFormat();

  const areSameDay = (start.startOf('day').valueOf() === end.startOf('day').valueOf());

  if (showAMPM) {
    if (areSameDay) {
      if ((start.hour < 12 && end.hour < 12) ||
        (start.hour >= 12 && end.hour >= 12)) {
        return start.setLocale(getI18nLanguage()).toFormat(`DDDD, ${timeFormat} - `) +
          end.setLocale(getI18nLanguage()).toFormat(`${timeFormat} a`);
      }
      return start.setLocale(getI18nLanguage()).toFormat(`DDDD, ${timeFormat} a - `) +
        end.setLocale(getI18nLanguage()).toFormat(`${timeFormat} a`);
    }
    return start.setLocale(getI18nLanguage()).toFormat(`DDDD, ${timeFormat} a - `) +
      end.setLocale(getI18nLanguage()).toFormat(`DDDD, ${timeFormat} a`);
  }

  if (areSameDay) {
    return start.setLocale(getI18nLanguage()).toFormat(`DDDD, ${timeFormat} - `) +
      end.setLocale(getI18nLanguage()).toFormat(`${timeFormat}`);
  }
  return start.setLocale(getI18nLanguage()).toFormat(`DDDD, ${timeFormat} - `) +
    end.setLocale(getI18nLanguage()).toFormat(`DDDD, ${timeFormat}`);
}

/**
 * Renders a formatted short time interval string in 12 or 24h time formats depending on user locale
 *  ONLY the time interval, no date included.
 * @param {Date} start
 * @param {Date} end
 * @param {DateTime} timeFormatSetting
 * @return {String} short time interval string
 */
export function renderTimeIntervalShort(start, end, timeFormatSetting) {
  const {showAMPM, timeFormat} = timeFormatSetting ?
    {
      showAMPM: timeFormatSetting !== '24h',
      timeFormat: getTimeFormatFromSetting(timeFormatSetting),
    } :
    getTimeFormat();

  const isSameAMPM = (start.getHours() < 12 && end.getHours() < 12) ||
    (start.getHours() >= 12 && end.getHours() >= 12);

  if (showAMPM) {
    if (isSameDay(start, end) && isSameAMPM) {
      // only show the am / pm of the second interval
      return format(start, timeFormat) + ' - ' + format(end, timeFormat + ' a');
    }
    return format(start, timeFormat + ' a') + ' - ' + format(end, timeFormat + ' a');
  }
  return format(start, timeFormat) + ' - ' + format(end, timeFormat);
};

/**
 * Renders a formatted short time string in 12 or 24h time formats depending on user locale
 *  ONLY the time, no date included.
 * @param {DateTime} dateTime
 * @param {DateTime} timeFormatSetting
 * @return {String} short time string
 */
export function renderTime(dateTime, timeFormatSetting) {
  const {showAMPM, timeFormat} = timeFormatSetting ?
    {
      showAMPM: timeFormatSetting !== '24h',
      timeFormat: getTimeFormatFromSetting(timeFormatSetting),
    } :
    getTimeFormat();
  if (showAMPM) {
    return dateTime.toFormat(timeFormat + ' a');
    // return format(dateTime, timeFormat + ' a');
  }
  return dateTime.toFormat(timeFormat);
  // return format(dateTime, timeFormat);
};


/**
 * format name for the avatar component
 * @param {String} email
 * @return {Array<String>}
 */
export function parseName(email) {
  try {
    if (typeof email !== 'string' || email === '') {
      return ['No Email Received'];
    }
    let fullname;
    if (!email.includes('@')) {
      fullname = email;
    } else {
      fullname = email.toLowerCase().slice(0, email.lastIndexOf('@'));
    }
    const names = fullname.split('.');
    if (names.length !== 2) {
      return [fullname];
    }
    const out = [];
    for (let i = 0; i < names.length; i++) {
      out.push(names[i].charAt(0).toUpperCase() + names[i].slice(1));
    }
    return out;
  } catch {
    return [email || 'No Email Received'];
  }
}

/**
 * format query object to event info
 * @param {Object} eventInfo
 * @param {?Function} getEventBookerInfo
 * @return {Object}
 */
export function formatEventInfo(eventInfo, getEventBookerInfo) {
  const bookers = eventInfo.attendees.reduce((previous, current) => {
    if (current.booker) {
      previous.push({
        email: current.email,
        firstName: current?.firstName,
        lastName: current?.lastName,
        name: current.displayName,
        phoneNumber: current.phoneNumber,
        responseStatus: current.responseStatus,
      });
    }
    return previous;
  }, []);

  let summary = '(No title)';
  if (eventInfo.summary !== undefined) {
    summary = eventInfo.summary;
  }

  const addOnType = getEventAddOnType(eventInfo);
  const location = parseLocation(eventInfo.location, addOnType);

  let bookerInfo;
  if (getEventBookerInfo) {
    bookerInfo = getEventBookerInfo(eventInfo);
  }

  const formatted = {
    eventId: eventInfo.id,
    assignedTo: eventInfo.assignedTo,
    appointmentId: eventInfo.appointmentId,
    bookingMethod: eventInfo?.bookingMethod,
    summary,
    bookers,
    location,
    addOnType,
    timeZone: eventInfo.start.timeZone,
    status: eventInfo.status,
    hostEmail: eventInfo.organizer?.email,
    attendees: eventInfo.attendees,
    bookerInfo: bookerInfo,
    originalEvent: eventInfo,
    hostDisplayName: eventInfo?.assignedTo?.[0] || '',
    guests: (!!eventInfo?.guests?.length) ?
      eventInfo?.guests?.map((guest) => guest.email) :
      [],
    isCciEvent: eventInfo.hostType === 'cci' || isCciCalendar(eventInfo?.organizer?.email),
    fromSingleUseLink: eventInfo?.fromSingleUseLink ?? false,
    trackingParams: eventInfo?.trackingParams || {},
  };
  return formatted;
}

/**
 *
 * @param {String} apptId
 * @return {String}
 */
export function getParentApptId(apptId) {
  try {
    return apptId.split('_')[0];
  } catch {
    return '';
  }
}

/**
 * @return {Boolean}
 */
export function isDevEnvironment() {
  return (
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
  );
}

/**
 * @return {Boolean}
 */
export function isNonProdEnv() {
  return window.location.hostname?.includes('localhost') ||
    ['scheduler.zoomdev.us', 'scheduler.acqa.zoomdev.us'].includes(window.location.hostname);
}

/**
 * Support skipping captcha tests for dev environments - for e2e + sla tests
 * @param {Boolean} isTestDefault initial value of isTest
 * @return {Boolean}
*/
export function skipCaptchaTests(isTestDefault) {
  const isDev = isNonProdEnv();
  const isTest = isTestDefault || new URLSearchParams(window.location.search).get('testing') === 'true';
  return isDev && isTest;
}

export {useErrorHandler, useQueryWithRetryCaptcha, useEnhancedQuery, useEnhancedQueryWithoutCaptcha} from './Captcha';

export const indexedToArr = (obj) => {
  const res = [];
  Object.keys(obj).forEach((key) => {
    res.push(obj[key]);
  });
  return res;
};

// Return new object indexed by key of array objects
export const indexByKey = (arr, key) => {
  const indexed = {};
  arr.forEach((entity) => {
    indexed[entity[key]] = {...entity};
  });
  return indexed;
};

/**
 * merges / upserts entities from newArr into shallow copy of arr
 *  using key as the entity id. If arr is undefined,
 *  returns new array containing just values of newArr
 * @param {?Array} arr array of entities to merge into
 * @param {Array} newArr array of entities to upsert
 * @param {string} key field to access key of the given entities
 * @return {Array} return a new array containing entities from arr and newArr
 *  merged along key
 */
export const merge = (arr, newArr, key) => {
  const entities = indexByKey(arr || [], key);
  for (const entity of newArr) {
    const id = entity[key];
    entities[id] = entity;
  }
  return indexedToArr(entities);
};

/**
 * Helper function to wrap all notification variables with the
 * correct data tags to prepopulate the variables properly in the editor
 * Ex. {{varName}} -> <span...>{{varName}}</span>
 * Will not wrap with anchor tagss
 * @param {String} description
 * @param {Array} varList
 * @param {Boolean} isSubjectOrSMS
 * @param {String} id
 * @return {String}
 */
export const replaceWithMention = (description, varList) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(description, 'text/html');
  const allTextNodes = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT);
  let tmpnode;
  let tmptxt;
  const doubleCurlyRE = /\{\{([^{}]*)\}\}/g;

  const modifyNodes = [];
  while (allTextNodes.nextNode()) {
    let ind = 0;
    tmpnode = allTextNodes.currentNode;
    tmptxt = tmpnode.nodeValue;
    const matches = Array.from(tmptxt.matchAll(doubleCurlyRE));
    const splitNodes = [];
    for (const match of matches) {
      if (match) {
        const [matchStr, matchVar] = match;
        if (varList.includes(matchVar)) {
          if (
            tmpnode.parentElement.tagName === 'SPAN' &&
            tmpnode.parentElement.getAttribute('data-id') === matchVar &&
            tmpnode.parentElement.getAttribute('data-type') === 'mention'
          ) {
            // this mention tag is already wrapped; skip it
            continue;
          }
          const normalPreText = match.input.substring(ind, match.index);
          if (normalPreText) {
            splitNodes.push(doc.createTextNode(normalPreText));
          }
          ind = match.index + matchStr.length;
          const mentionNode = doc.createElement('span');
          mentionNode.setAttribute('data-type', 'mention');
          mentionNode.className = 'mention';
          mentionNode.setAttribute('data-id', matchVar);
          mentionNode.textContent = `{{${matchVar}}}`;
          splitNodes.push(mentionNode);
        }
      }
    }

    if (splitNodes.length) {
      const normalPreText = tmptxt.substring(ind, tmptxt.length);
      if (normalPreText) {
        splitNodes.push(doc.createTextNode(normalPreText));
      }
      modifyNodes.push({
        node: allTextNodes.currentNode,
        newChildren: splitNodes,
      });
    }
  }
  for (const nodeMod of modifyNodes) {
    const currNode = nodeMod.node;
    if (currNode.parentNode) {
      for (const newChild of nodeMod.newChildren) {
        currNode.parentNode.insertBefore(newChild, currNode);
      }
      currNode.parentNode.removeChild(currNode);
    }
  }
  return doc.body.innerHTML;
};

/**
 * Expands a plain text string to html rich text in order to preserve white space
 * formatting within Tiptap editor
 * @param {string} string
 * @return {string}
 */
export const convertPlainToRichText = (string) => {
  if (string) {
    const segs = string.split('\n\n');
    if (segs.length) {
      return '<p>' + segs.join('</p><p>') + '</p>';
    }
  }
  return string;
};

/**
 * linear usage of promise
 * @return {Object} promise, reject, and resolve
 */
export const deferred = () => {
  let resolve;
  let reject;
  const promise = new Promise((res, rej) => {
    [resolve, reject] = [res, rej];
  });
  return {promise, reject, resolve};
};

/**
 * Helper function to populate notification variables with the
 * predefined data for the template preview
 * Ex. {{varName}} -> <span...>{{varName}}</span>
 * @param {String} text
 * @param {Object} vars
 * @param {Boolean} escapeVars
 * @return {String}
 */
export const populateMentionValues = (text, vars, escapeVars) => {
  if (!text || !vars) {
    return text;
  }
  let updated = text;
  updated = updated.replaceAll(`href="[INSERT_LINK]"`, `href="[INSERT_LINK]" style="pointer-events: none;"`);
  Object.entries(vars).forEach(([key, value]) => {
    updated = updated
      .replaceAll(
        `href="{{${key}}}"`,
        `href="{{${key}}}" style="pointer-events: none;"`
      )
      .replaceAll(
        `{{${key}}}`,
        `${escapeVars ? escape(value) : value}`
      );
  });
  return updated;
};

/**
 * Try to read event's zmCreateEventType enum and convert to addOnType enum
  event: {
    ...
    "shared": {
        "ZOOM_ConferenceNumber": "999999999",
        "ZOOM_ConferencePassword": "012345",
        "zmCreateEventType": "1", <-- Convert to addOnType enum
        "zmMeetingNum": "999999999"
    }
  }
 * @param {Object} serverEvent
 * @return {String}
 */
export const getEventAddOnType = (serverEvent) => {
  let addOnType = '';
  const zmMeetingType = serverEvent?.extendedProperties?.shared?.zmCreateEventType;
  switch (zmMeetingType) {
    case ZM_CREATE_TYPE.ZOOM_MEETING:
      addOnType = ADD_ON_TYPE.ZOOM_MEETING;
      break;
    case ZM_CREATE_TYPE.ZOOM_PHONE:
      addOnType = ADD_ON_TYPE.ZOOM_PHONE;
      break;
    default:
      addOnType = ADD_ON_TYPE.OFFLINE;
      break;
  }
  return addOnType;
};

/**
 * Helper function to parse appointment location values
 * e.g. 'Example Location;https://zoom.us/j/123456' -> 'Example Location'
 * @param {String} text
 * @param {String} meetingType
 * @return {String}
 */
export const parseLocation = (text, meetingType) => {
  if (meetingType === ADD_ON_TYPE.ZOOM_MEETING || meetingType === ADD_ON_TYPE.ZOOM_PHONE) {
    if (text?.includes(';')) {
      return text?.slice(0, text?.lastIndexOf(';'));
    }
    // No actual location was provided; only conference links
    return '';
  }
  // If offline, return location literally
  return text;
};

/**
 * Helper function to get the booker's information from the server event response by email
 * @param {Object} eventInfo
 * @param {String} searchEmail
 * @return {?Object}
 */
export const getBookerInfoByEmail = (eventInfo, searchEmail) => {
  return eventInfo?.attendees?.find((attendee) => attendee.email === searchEmail);
};

/**
 * Helper function to get the booker's information from the server event response by attendeeId
 * @param {Object} eventInfo
 * @param {String} searchId
 * @return {?Object}
 */
export const getBookerInfoByAttendeeId = (eventInfo, searchId) => {
  return eventInfo?.attendees?.find((attendee) => attendee.id === searchId);
};

/*
* Helper Function to return users current timezone
 * @return {String}
 */
export const getUserTimeZone = () => {
  try {
    // eslint-disable-next-line new-cap
    return Intl.DateTimeFormat().resolvedOptions().timeZone; // Format: "Continent/CityOrCountry"
  } catch (e) {
    return '';
  }
};

/**
 * Helper Function to get the appointment type
 * @param {Appt} appt
 * @return {APPT_TYPE}
 */
export const getApptType = (appt) => {
  if (!!appt?.bookingMethod) {
    return appt?.bookingMethod;
  } else if (!!appt?.poolingType) {
    return appt?.poolingType;
  } else if (!!getApptIntervalType(appt)) {
    return APPT_TYPE.RECURRING;
  } else {
    return APPT_TYPE.CUSTOM;
  }
};

/**
 * Helper Function to get the capacity type
 * @param {Appt} appt
 * @return {CAPACITY_TYPE}
 */
export const getCapacityType = (appt) => {
  const apptCapacity = appt?.capacity || 1;
  if (apptCapacity > 1) {
    return CAPACITY_TYPE.MULTIPLE;
  }
  return CAPACITY_TYPE.ONE;
};

export const getVirtualUserEmail = (uid) => {
  return `${uid}@scheduler.zoom.us`;
};

// appt assigned to the team has following creator format: `team_xxxx@scheduler.zoom.us`
export const isCreateForTeamAppt = (appt) => {
  const creator = appt?.creator?.email;
  return creator?.startsWith('team_') && creator?.endsWith('@scheduler.zoom.us');
};

export const getZCCVirtualUserEmail = (jwtPayload) => {
  if (!jwtPayload) {
    try {
      jwtPayload = readJWTPayload(getToken());
    } catch (error) {
      console.warn('parse token failed');
    }
  }
  return `contact_center_${jwtPayload?.aid?.toLowerCase()}@group.scheduler.zoom.us`;
};

/**
 *
 * @param {string} calendarId
 * @return {string}
 */
export const virtualUserEmailToUid = (calendarId) => {
  try {
    // handle zcc case
    if (calendarId.startsWith('contact_center_')) {
      return calendarId.split('contact_center_')[1].split('@')[0] || '';
    }
    const selfUid = calendarId.split('@')[0] || '';
    return selfUid;
  } catch {}
  return '';
};

export const getOrganizerUid = (appt) => {
  const organizerEmail = appt?.attendees?.find((attendee) => attendee.organizer)?.id || appt?.organizer?.email;
  return virtualUserEmailToUid(organizerEmail);
};

export const shouldShowAdditionalNotification = (appt, isManagedEvent, selectHostUser) => {
  if (isManagedEvent) {
    return false;
  }
  const {userId} = getUserInfo() || {};
  let hostUid;
  if (appt) { // editing appt
    hostUid = virtualUserEmailToUid(getOwnerUidEmail(appt));
  } else if (selectHostUser) { // creating as admin
    hostUid = selectHostUser?.userId ||
    virtualUserEmailToUid(selectHostUser?.email);
  } else { // creating for users themselves
    return true;
  }
  return userId && hostUid &&
    userId.toLocaleLowerCase() === hostUid.toLocaleLowerCase();
};

/** @typedef OwnershipStatus
 * @property {boolean} isManagedByAdmin
 * @property {boolean} isSharedWithYou
 * @property {boolean} isOrganizer current user is the organizer of the appt
 * @property {boolean} isNonPrimary
 * @property {HostAttendee[]} apptOwner
 * @property {HostAttendee[]} apptHost
*/

/**
 * Get current user's access role for the given appt - only works for non-adhoc appts
 * @param {Appt} appt
 * @param {Scope} asScope
 * @return {string} ACCESS_ROLE enum
 */
export const getUserApptAccessRole = (appt, asScope) => {
  if (asScope.type === SCOPE_TYPE.TEAM) {
    return asScope.isTeamAdmin ? ACCESS_ROLES.owner : ACCESS_ROLES.reader;
  }
  const selfUser = getUserInfo();
  if (selfUser.isCciAccount) {
    return ACCESS_ROLES.owner;
  }
  let calId = getVirtualUserEmail(asScope?.id);
  if (!asScope) {
    calId = getUserInfo()?.calendarId;
  }
  const {attendees, organizer} = appt || {};
  // NOTE: attendee.self is incorrect when admin creates for other user.
  // Use calendarId comparison to determine self.
  const self = attendees?.find((item) => strEq(item?.email, calId));
  if (!!self && !!self.accessRole) {
    return self.accessRole;
  }
  return strEq(organizer?.email, calId) ? ACCESS_ROLES.owner : ACCESS_ROLES.writer;
};

/**
 * @param {Appt} appt
 * @param {Scope} asScope
 * @return {boolean}
 */
export const currUserCanEdit = (appt, asScope) => {
  const role = getUserApptAccessRole(appt, asScope);
  return role === ACCESS_ROLES.owner || role === ACCESS_ROLES.writer;
};

/**
 * @param {Appt} appt
 * @return {string} uid email string of the owner if exists and organizer if not
 */
export const getOwnerUidEmail = (appt) => {
  const owner = (appt?.attendees || []).find((att) => att.accessRole === ACCESS_ROLES.owner);
  if (owner?.id) {
    return owner.id;
  } else {
    return appt?.organizer?.email;
  }
};

/**
 * Gets the permission / share status information for an appointment
 * @param {Appt} appt
 * @param {Scope} userScope
 * @return {OwnershipStatus}
 */
export const getOwnershipStatus = (appt) => {
  const userInfo = getUserInfo();
  const isTeam = isTeamAppt(appt);
  const currentEmail = (userInfo?.calendarId || '').toLowerCase();
  const hostEmail = (appt.organizer?.email || '').toLowerCase();
  const apptOwner = appt.attendees?.find((user) => user.accessRole === ACCESS_ROLES.owner);
  const apptHost = appt.attendees?.find((user) => (user.email || '').toLowerCase() === hostEmail);

  const selfIsOrganizer = hostEmail === currentEmail;
  const selfAsAttendee = appt.attendees?.find((user) => strEq(user.email, currentEmail));
  return {
    isManagedByAdmin:
      !isTeam && selfIsOrganizer && apptOwner && apptOwner.id.toLowerCase() !== currentEmail &&
      appt.creatorType === APPT_CREATOR_TYPE.ORG,
    // if self is not the organizer and not one of the co-hosts, then it is an appt shared with me
    isSharedWithYou: !selfIsOrganizer && selfAsAttendee && !selfAsAttendee.host,
    // self is not the organizer but is in the appt.attendees list
    isNonPrimary: !selfIsOrganizer && selfAsAttendee,
    isOrganizer: selfIsOrganizer,
    apptOwner: [apptOwner],
    apptHost: [apptHost],
    ownerCalId: (appt.attendees?.find((user) => user.accessRole === ACCESS_ROLES.owner) ?? appt.organizer)?.email,
  };
};

/**
 * Determines if the connection validation result has a server-blocking error:
 * - if the user has a mismatched account id
 * @param {ConnValidationError} validationResult
 * @return {boolean}
 */
export const userHasBlockingError = (validationResult) => {
  if (validationResult?.error && validationResult.error.match(/Mismatched account/i)) {
    return true;
  }
  return false;
};

/**
 * Determines if the connection validation result has an invalid license error that would block team creation
 * @param {ConnValidationError} validationResult
 * @return {boolean}
 */
export const userHasLicenseError = (validationResult) => {
  if (validationResult?.error && validationResult.error.match(/license/i)) {
    return true;
  }
  return false;
};

const isLightColor = (color) => {
  const hex = color.replace('#', '');
  const red = parseInt(hex.substring(0, 0 + 2), 16);
  const green = parseInt(hex.substring(2, 2 + 2), 16);
  const blue = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = ((red * 299) + (green * 587) + (blue * 114)) / 1000;
  // return brightness > 155;
  return brightness > 95;
};

export const lightenDarkenColor = (color, amount) => {
  if (isLightColor(color)) {
    amount = -Math.abs(amount);
  } else {
    amount = Math.abs(amount) + 20;
  }
  const clamp = (val) => Math.min(Math.max(val, 0), 0xFF);
  const fill = (str) => ('00' + str).slice(-2);

  const num = parseInt(color.substr(1), 16);
  const red = clamp((num >> 16) + amount);
  const green = clamp(((num >> 8) & 0x00FF) + amount);
  const blue = clamp((num & 0x0000FF) + amount);
  return '#' + fill(red.toString(16)) + fill(green.toString(16)) + fill(blue.toString(16));
};

export const getTransparentColor = (color, alpha) => {
  const hex = color.replace('#', '');
  const red = parseInt(hex.substring(0, 0 + 2), 16);
  const green = parseInt(hex.substring(2, 2 + 2), 16);
  const blue = parseInt(hex.substring(4, 4 + 2), 16);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

export const getSafeScheduleColorValue = (color) => {
  const allowesColors = SCHEDULE_COLOR_IDS.map((item) => item.value);
  if (allowesColors.includes(color)) {
    return color;
  }
  return '';
};

export const transformObjectKeyToLowerCase = (obj) => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => transformObjectKeyToLowerCase(item));
  }

  const newObj = {};
  Object.keys(obj).forEach((key) => {
    newObj[key.toLowerCase()] = transformObjectKeyToLowerCase(obj[key]);
  });

  return newObj;
};

// Because luxon is not respecting locale week setting at the momemnt
// It considers Monday as start of the week instead of Sunday
// We need this workaround to find the start of week
// https://github.com/moment/luxon/issues/373
export const getStartOfWeek = (luxonDate) => {
  return luxonDate
    .plus({days: 1})
    .startOf('week')
    .minus({days: 1});
};

// Because luxon is not respecting locale week setting at the momemnt
// It considers Sunday as end of the week instead of Saturday
// We need this workaround to find the end of week
// https://github.com/moment/luxon/issues/373
export const getEndOfWeek = (luxonDate) => {
  return getStartOfWeek(luxonDate).plus({week: 1, millisecond: -1});
};

/**
 * Get the fist day of current month on the calendar view
 * For example, if today is Wednesday 12/06/2023
 * the first day of this month would be Friday 12/01/2023
 * then the first day of that week would be Sunday 11/26/2023 which is the first day on the calendar
 * @param {Date} date
 * @param {string} timeZone
 * @param {boolean} keepLocalTime
 * @return {DateTime}
 */
export const getFirstDayOnCalendar = (date, timeZone, keepLocalTime) => {
  return getStartOfWeek(toDateTime(date ? new Date(date) : new Date(), timeZone, keepLocalTime).startOf('month'));
  // return startOfWeek(startOfMonth(date ? new Date(date) : new Date())).toISOString();
};

// Get the last day of current month on the calendar view
// For example, if today is Wednesday 12/06/2023
// the first day of this month would be Sunday 12/31/2023
// then the first day of that week would be Saturday 01/06/2024 which is the last day on the calendar
export const getLastDayOnCalendar = (date, timeZone, keepLocalTime) => {
  return getEndOfWeek(toDateTime(date ? new Date(date) : new Date(), timeZone, keepLocalTime).endOf('month'));
  // return endOfWeek(endOfMonth(date ? new Date(date) : new Date())).toISOString();
};

/**
 *
 * @param {Date} viewingMonth
 * @param {string} timeZone
 * @return {DateTime[]}
 */
export const expandMonthToWeeks = (viewingMonth, timeZone) => {
  const zonedMonthStart = toDateTime(viewingMonth, timeZone, true);
  const firstWeek = getStartOfWeek(zonedMonthStart.startOf('month'));
  const lastWeek = getEndOfWeek(zonedMonthStart.endOf('month'));
  return Interval.fromDateTimes(firstWeek, lastWeek).splitBy({week: 1}).map((interval) => interval.start);
};

/**
 *
 * @param {Date} timeMin
 * @param {Date} timeMax
 * @param {string} timeZone
 * @return {DateTime[]}
 */
export const expandRangeToWeeks = (timeMin, timeMax, timeZone) => {
  const zonedStart = toDateTime(timeMin, timeZone, true);
  const zonedEnd = toDateTime(timeMax, timeZone, true);
  const firstWeek = getStartOfWeek(zonedStart);
  const lastWeek = getEndOfWeek(zonedEnd);
  return Interval.fromDateTimes(firstWeek, lastWeek)
    .splitBy({week: 1})
    .filter((interval) => interval.end.diffNow().milliseconds > 0)
    .map((interval) => interval.start);
};

export const mapDaysData = (daysData) => {
  return Object.fromEntries(daysData.map(
    (dayData) => [
      dayData.date, // Object keys
      dayData, // Object values
    ]
  ));
};

export const mergeDaysData = (oldData, newData) => {
  const oldDays = mapDaysData(oldData);
  const newDays = mapDaysData(newData);
  const mergedDays = Object.assign(oldDays, newDays);

  return Object.values(mergedDays);
};

export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

/**
 * Normalize the attendees array to support old and new appt attendees formats
 * @param {HostAttendee[]} attendees
 * @param {Object} organizer
 * @param {boolean} isTeam
 * @param {Scope} asScope
 * @return {HostAttendee[]}
 */
export const initializeAttendeeList = (attendees = [], organizer, isTeam, asScope) => {
  const newList = cloneDeep(attendees);
  // const organizerUid = virtualUserEmailToUid(organizer?.email) || calendarId;
  const selfUser = getUserInfo();
  const selectHostUser = asScope?.type === SCOPE_TYPE.USER ?
  {
    userId: asScope.id,
    email: asScope.email,
    name: asScope.name,
  } : {
    userId: selfUser?.userId,
    email: selfUser?.loginEmail,
    name: selfUser?.userName,
  };
  let calendarId;
  if (selfUser?.isCciAccount) {
    calendarId = getZCCVirtualUserEmail({aid: selfUser?.accountId});
  } else {
    calendarId = getVirtualUserEmail(selectHostUser?.userId);
  }

  if (newList?.length) {
    const hasOwner = !!newList?.find((i) => i.accessRole === ACCESS_ROLES.owner);

    return newList.map((item) => {
      let accessRole;
      if (item.accessRole) {
        accessRole = item.accessRole;
      } else if (!hasOwner && (item.email === organizer?.email || item.organizer)) {
        accessRole = asScope?.type === SCOPE_TYPE.TEAM ? ACCESS_ROLES.reader : ACCESS_ROLES.owner;
      } else {
        accessRole = ACCESS_ROLES.writer;
      }
      return {
        ...item,
        accessRole,
        email: (item.email || calendarId)?.toLowerCase(),
        id: (item.id || item.email || calendarId)?.toLowerCase(),
        host: isTeam ? item.host !== false : (item.email === organizer?.email || !!item.organizer),
      };
    });
  }
  return [{
    ...organizer,
    accessRole: asScope?.type === SCOPE_TYPE.TEAM ? ACCESS_ROLES.reader : ACCESS_ROLES.owner,
    displayName: organizer?.displayName || selectHostUser?.name,
    email: calendarId?.toLowerCase(),
    id: calendarId?.toLowerCase(),
    host: true,
    organizer: true,
    loginEmail: selectHostUser.email,
  }];
};

/**
 * @param {Appt} appt
 * @param {Scope} currScope
 * @return {boolean}
 */
export const visibleToScope = (appt, currScope) => {
  if (isCciCalendar(appt.organizer?.email)) {
    return true;
  }

  let scope = currScope;
  if (!scope?.type) {
    scope = selfToScope(getUserInfo());
  }
  if (isCreateForTeamAppt(appt) && scope.type === SCOPE_TYPE.TEAM) {
    // Team-owned appt and we are viewing that team's appts.
    return appt.creator.email.includes(scope.id);
  }
  if (scope.type === SCOPE_TYPE.USER) {
    // The given user is in the attendee list or is the organizer
    const calId = getVirtualUserEmail(scope.id);
    if (appt.attendees) {
      return appt.attendees.some((att) => strEq(att.email, calId));
    }
    return strEq(appt.organizer.email, calId);
  }
  return false;
};

/*
 * string comparison
 * @param {string} s1
 * @param {string} s2
 * @param {?boolean} caseInsensitive
 * @return {boolean}
 */
export const strEq = (s1, s2, caseInsensitive = true) => {
  if (caseInsensitive) {
    try {
      return s1?.toLowerCase() === s2?.toLowerCase();
    } catch {
      console.error('failed to compare', s1, s2);
      return false;
    }
  }
  return s1 === s2;
};

export const sendGTMEventToOP = (payload) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(payload);
  try {
    if (window.parent !== null) {
      window.parent.postMessage({
        type: 'dataLayer', data: window.dataLayer?.slice(-1),
      }, '*');
    }
  } catch (e) {
    console.error('Scheduler Paywall is unable to push event to OP', e);
  }
};

export const getTextOverflowStyle = (maxLines) => {
  return {
    'wordBreak': 'break-word',
    'display': '-webkit-box',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    '-webkit-line-clamp': `${maxLines}`,
    '-webkit-box-orient': 'vertical',
  };
};

export const compareSemVer = (a, b) => {
  const pa = a.split('.');
  const pb = b.split('.');
  for (let i = 0; i < 3; i++) {
    const na = Number(pa[i]);
    const nb = Number(pb[i]);
    if (na > nb) return 1;
    if (nb > na) return -1;
    if (!isNaN(na) && isNaN(nb)) return 1;
    if (isNaN(na) && !isNaN(nb)) return -1;
  }
  return 0;
};

export const defaultTz = getCanonicalTz(new Intl.DateTimeFormat().resolvedOptions().timeZone) || 'Etc/UTC';

export const isZoomieEmail = (email) => email?.endsWith('zoom.us') || email?.endsWith('zoom.com');

export const unionManagedTeamsAndMemberTeams = (managedTeams, memberTeams) => {
  return unionBy(managedTeams, memberTeams, 'id');
};

export const addParamsToUrl = (url, newParams) => {
  if (typeof newParams !== 'object') {
    return url;
  }
  const urlObj = new URL(url);
  const params = urlObj.searchParams;
  for (const key of Object.keys(newParams)) {
    params.delete(key);
    params.append(key, newParams[key]);
  }
  return urlObj.toString();
};

export const isBitSet = (number, bitPosition) => {
  if (typeof number !== 'number') {
    return false;
  }
  return (number & (1 << bitPosition)) !== 0;
};

/**
 * get request which pathname match regex
 * @param {String} pathName
 * @return {RegExp}
 */
export function getReqWithIdRegex(pathName) {
  return REQ_WITH_ID_REGX.find((re) => re.test(pathName));
};
